import axios from 'axios';
import { baseUrl } from 'constants/url';
import React, { useEffect, useRef, useState } from 'react'
import { Spinner } from 'reactstrap';
import authHeader from 'services/auth-header';
import Table from "views/shared/Table";

const OperatorsInfo = ({
  lineName, clientId,
  isLoading = true
}) => {
  const [tableData, setTableData] = useState('Loading...');
  const today = new Date();
  const todayGetData = new Date();
  const yesterdayGetData = new Date(today);
  const timerRef = useRef(null);

  const tableColumns = [
    {
      id: "name",
      title: "Operator",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "total_packs",
      title: "Total Packs",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "weight_packed",
      title: "Weight Packed (g)",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "Avg_Speed",
      title: "Avg Speed",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "count",
      title: "Total Minutes",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "T1PPM",
      title: "T1PPM",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "GA",
      title: "GA",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },
  ];

  const getData = async (initialReq) => {
    try {
      const res = await axios.get(`${baseUrl}/api/dashboard/operators-info`, {
        params: {
          start_date: yesterdayGetData.toISOString(),
          // start_date: "2016-01-01T00:00:00Z",
          end_date: todayGetData.toISOString(),
          // end_date: "2022-08-04T23:59:59Z",
          clientId,
          lineName: lineName,
          initialReq: initialReq,
          Timestamp: tableData[0] ? tableData[0].Timestamp : "",
        },
        headers: authHeader(),
      })

      setTableData(res.data.tableData);

    } catch (err) {
      setTableData([])
    }
    finally {
      timerRef.current = setTimeout(() => {
        getData();
      }, 30000);
    }
  };

  useEffect(() => {
    if (isLoading || !lineName) return;
    getData('yes');
    timerRef.current = setTimeout(() => {
      getData('no');
    }, 30000);
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [isLoading, lineName])

  return (
    <div>
      <Table
        values={[...tableData]}
        columns={tableColumns}
        tableClasses={{
          table:
            "table align-items-center table-flush table-sm border-bottom text-white table-custom",
          thead: "",
          tbody: "list table-td-color",
        }}
        isLoading={tableData === 'Loading...'}
      />

    </div>
  )
}

export default OperatorsInfo