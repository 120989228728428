import mwImg from "../assets/img/brand/MiWeigh_Large.png";
import headerImg from "../assets/img/brand/BATCH_REPORT_TITLE.png";
import paImg from "../assets/img/brand/pa_logo.png";
const { jsPDF } = require("jspdf");
require("jspdf-autotable");
let window = {};
const moment = require("moment");

const add_data_row_recipe_custom = (
  doc_obj,
  row,
  label_1,
  label_2,
  value_1,
  value_2
) => {
  let col_label_1 = 10;
  let col_value_1 = 60;
  let col_label_2 = 110;
  let col_value_2 = 140;
  value_1 = value_1 || value_1 === 0 ? value_1 : "";
  value_2 = value_2 || value_2 === 0 ? value_2 : "";
  doc_obj.setFont("times", "bold");
  doc_obj.text(label_1, col_label_1, row);
  doc_obj.text(label_2, col_label_2, row);
  doc_obj.setFont("times", "normal");
  doc_obj.text(value_1.toString(), col_value_1, row);
  doc_obj.text(value_2.toString(), col_value_2, row);
};

const add_data_row = (doc_obj, row, label_1, label_2, value_1, value_2) => {
  let col_label_1 = 10;
  let col_value_1 = 60;
  let col_label_2 = 110;
  let col_value_2 = 160;
  value_1 = value_1 || value_1 === 0 ? value_1 : "";
  value_2 = value_2 || value_2 === 0 ? value_2 : "";
  doc_obj.setFont("times", "bold");
  doc_obj.text(label_1, col_label_1, row);
  doc_obj.text(label_2, col_label_2, row);
  doc_obj.setFont("times", "normal");
  doc_obj.text(value_1.toString(), col_value_1, row);
  doc_obj.text(value_2.toString(), col_value_2, row);
};

const add_data_row_notes = (
  doc_obj,
  row,
  label_1,
  label_2,
  value_1,
  value_2
) => {
  let col_label_1 = 10;
  let col_value_1 = 23;
  let col_label_2 = 110;
  let col_value_2 = 160;
  value_1 = value_1 || value_1 === 0 ? value_1 : "";
  value_2 = value_2 || value_2 === 0 ? value_2 : "";
  doc_obj.setFont("times", "bold");
  doc_obj.text(label_1, col_label_1, row);
  doc_obj.text(label_2, col_label_2, row);
  doc_obj.setFont("times", "normal");
  doc_obj.text(value_1.toString(), col_value_1, row);
  doc_obj.text(value_2.toString(), col_value_2, row);
};

const add_data_row_three_columns = (
  doc_obj,
  row,
  label_1,
  label_2,
  label_3,
  value_1,
  value_2,
  value_3
) => {
  let col_label_1 = 10;
  let col_label_2 = 76;
  let col_label_3 = 142;
  let col_value_1 = 40;
  let col_value_2 = 106;
  let col_value_3 = 172;
  value_1 = value_1 || value_1 === 0 ? value_1 : "";
  value_2 = value_2 || value_2 === 0 ? value_2 : "";
  value_3 = value_3 || value_3 === 0 ? value_3 : "";
  doc_obj.setFont("times", "bold");
  doc_obj.text(label_1, col_label_1, row);
  doc_obj.text(label_2, col_label_2, row);
  doc_obj.text(label_3, col_label_3, row);
  doc_obj.setFont("times", "normal");
  doc_obj.text(value_1.toString(), col_value_1, row);
  doc_obj.text(value_2.toString(), col_value_2, row);
  doc_obj.text(value_3.toString(), col_value_3, row);
};

const populate_table_body = (
  scales_ppm,
  scales_t1,
  scales_sp,
  scales_weight,
  batch_runtime,
  target_speed,
  start_set_point,
  start_product_e,
  start_tolerance
) => {
  let table_body = [];
  let srNo = 1;
  window.scales_kpi = [];
  for (const [operator, ppm] of Object.entries(scales_ppm)) {
    // // console.log(key, value);
    let t1 = scales_t1[operator];

    let ga = scales_weight[operator] - start_set_point;
    if (start_product_e === "0") {
      ga = ga + start_tolerance;
    }

    // let ga = (scales_weight[operator] - scales_sp[operator]) / ppm;

    ga = ga.toFixed(2);
    let kpi = (ppm / batch_runtime) * target_speed;
    // console.log(
    //   "PPM " + ppm + " RT " + batch_runtime + " Target " + target_speed
    // );
    kpi = kpi.toFixed(2);
    window.scales_kpi.push(kpi);
    let t1_perc = (t1 / ppm) * 100;
    // data.Recipe.length > 30 ? data.Recipe.slice(0, 30) + "..." : data.Recipe
    table_body.push([
      srNo,
      operator.length > 11 ? operator.slice(0, 11) + "..." : operator,
      ppm,
      kpi,
      ga,
      t1_perc.toFixed(2),
    ]);
    srNo++;
    //let temp = [key, value];
  }
  if (table_body.length < 15) {
    for (let i = 0; i < 15 - table_body.length; i++) {
      table_body.push([srNo, "", "", "", "", ""]);
      srNo++;
    }
  }
  return table_body;
};

const write_report_content = (
  doc_obj,
  data,
  st_data,
  scales_ppm,
  scales_t1,
  scales_sp,
  scales_weight,
  batch_runtime,
  target_speed,
  avg_speed
) => {
  console.log("Report End Data: ", data);
  console.log("Report Start Data: ", st_data);

  //config
  let greenColor = "#bac9a8";
  let grayColor = "#d0cece";
  doc_obj.setFontSize(14);

  // Header
  doc_obj.setLineWidth(0.5);
  doc_obj.rect(0, 0, 170, 25, "S");
  doc_obj.addImage(headerImg, "PNG", 0.5, 0.5, 169, 24);
  doc_obj.rect(170, 0, 40, 25, "S");
  doc_obj.addImage(paImg, "PNG", 177, 4.5, 26, 16);

  // First Block
  doc_obj.setFillColor(greenColor);
  doc_obj.rect(5, 28, 200, 20, "F");
  add_data_row_recipe_custom(
    doc_obj,
    36,
    "Batch Number",
    "Recipe",
    // data.Batch_ID,
    st_data.Batch_Number,
    data.Recipe.length > 25 ? data.Recipe.slice(0, 25) + "..." : data.Recipe
  );
  add_data_row(
    doc_obj,
    44,
    "Date & Time",
    "Product Weight",
    data.Timestamp ? data.Timestamp : data.TIMESTAMP,
    // data.Avg_Wght + "g"
    st_data.Set_Point + "g"
  );

  // Second Block
  doc_obj.setFillColor(grayColor);
  doc_obj.rect(5, 51, 200, 45, "F");
  add_data_row(
    doc_obj,
    59,
    "User Start",
    "User End",
    st_data.Line_Leader,
    data.Line_Leader
  );

  add_data_row(
    doc_obj,
    67,
    "Start Time",
    "End Time",
    st_data.Timestamp ? st_data.Timestamp : st_data.TIMESTAMP,
    data.Timestamp ? data.Timestamp : data.TIMESTAMP
  );
  let m = moment().utcOffset(0);
  m.set({ hour: 0, minute: data.Run_Time, second: 0, millisecond: 0 });
  m.toISOString();
  add_data_row(
    doc_obj,
    75,
    "Run Time",
    // 'Num Of Operators',
    "Line",
    // data.Run_Time,
    m.format("HH:mm:ss"),
    // st_data.Operator_Qt
    st_data.Line
  );
  add_data_row(
    doc_obj,
    83,
    "Run Time Minutes",
    "Num Of Scales",
    data.Run_Time,
    st_data.Scale_Qt
  );
  let p = moment().utcOffset(0);
  p.set({ hour: 0, minute: data.Pause_Time, second: 0, millisecond: 0 });
  p.toISOString();
  add_data_row(
    doc_obj,
    91,
    "Line Clear and Clean",
    "Pause Time",
    "Yes",
    p.format("HH:mm:ss")
  );

  // Third Table Graph Block
  let table_body = populate_table_body(
    scales_ppm,
    scales_t1,
    scales_sp,
    scales_weight,
    batch_runtime,
    target_speed,
    st_data.Set_Point,
    st_data.e,
    st_data.Tolerance
  );

  // if (table_body.length > 8) table_body = table_body.slice(0, 8);

  doc_obj.setFillColor(greenColor);
  doc_obj.rect(5, 99, 200, 95 + 30, "F");
  doc_obj.autoTable({
    head: [["", "Name", "Total Packs", "KPI %", "GA g", "T1 %"]],
    startY: 101,
    body: table_body,
    margin: { left: 6, right: 110 },
    tableLineColor: "#000000",
    tableLineWidth: 0.1,
    styles: {
      fillColor: greenColor,
      textColor: "#000000",
      lineColor: "#000000",
      lineWidth: 0.1,
      fontSize: 10,
    },
    // padding: 0,
    columnStyles: {
      0: { cellWidth: 10 },
      // 1: { cellWidth: 22, fontStyle: "bold" },
      1: { cellWidth: 34 },
      2: { cellWidth: 15 },
      3: { cellWidth: 15 },
      4: { cellWidth: 12 },
      5: { cellWidth: 12 },
    },
    theme: "grid",
  });

  doc_obj.setFillColor("#ffffff");
  doc_obj.rect(104, 100, 100, 95 + 30, "F");
  doc_obj.setFontSize(12);
  doc_obj.text("KPI%", 145, 105);
  doc_obj.setFontSize(6);
  for (let i = 0; i <= 20; i++) {
    doc_obj.text((10 * i).toString(), 118 + 4 * i, 110);
    doc_obj.setLineWidth(0.01);
    doc_obj.line(119 + 4 * i, 111, 119 + 4 * i, 190);
  }

  doc_obj.setFontSize(9);
  for (let i = 0; i < table_body.length; i++) {
    doc_obj.text(
      table_body[i][1].length > 5
        ? table_body[i][1].slice(0, 5) + ".."
        : table_body[i][1],
      105,
      117 + 8 * i
    );
    let kpiValue = Math.floor(table_body[i][3]);
    if (kpiValue > 99) doc_obj.setFillColor("#00FF00");
    else doc_obj.setFillColor("#800000");
    doc_obj.rect(
      119,
      114 + 8 * i,
      Math.floor(kpiValue * 0.4) > 80 ? 80 : Math.floor(kpiValue * 0.4),
      // 80,
      4,
      "F"
    );
  }

  doc_obj.setFontSize(16);
  doc_obj.setFont("times", "bold");
  doc_obj.text("Total KPI: ", 20, 190 + 45);
  doc_obj.text(data.KPI + "%", 65, 190 + 45);

  // Fourth Block
  doc_obj.setFillColor(grayColor);
  doc_obj.setFont("times", "normal");
  doc_obj.rect(5, 197 + 45, 200, 20, "F");
  doc_obj.setFontSize(12);

  // let prdWeightKg = data.Prd_Cost/((data.Avg_Wght * data.Total_Packs)/1000)
  add_data_row_three_columns(
    doc_obj,
    203 + 45,
    "Weight SP",
    "Tare",
    "Prd. Price/Kg",
    st_data.Set_Point + "g",
    st_data.Tare ? st_data.Tare + "g" : "",
    // "£"+prdWeightKg.toFixed(2)
    "£" + st_data.Prd_Cost
  );
  add_data_row_three_columns(
    doc_obj,
    209 + 45,
    "Tolerance",
    "Labour/hour",
    "Extras/Pack",
    st_data.Tolerance + "g",
    "£" + st_data.Lbr_Cost,
    // "£"+(data.Total_Packs/data.Extr_Cost).toFixed(2)
    "£" + st_data.Extr_Cost
  );
  add_data_row_three_columns(
    doc_obj,
    215 + 45,
    "Target Speed",
    "",
    "",
    st_data.Speed + " ppm"
  );

  // Fifth Block
  doc_obj.setFillColor(greenColor);
  doc_obj.rect(5, 220 + 45, 200, 40, "F");
  doc_obj.setFontSize(12);

  add_data_row(
    doc_obj,
    226 + 45,
    "Total Packs",
    "Average Weight",
    data.Total_Packs,
    data.Avg_Wght + "g"
  );
  add_data_row(
    doc_obj,
    232 + 45,
    "Cost/Pack",
    "Average Speed",
    "£" + data.Pack_Cost,
    // data.Avg_Speed + " ppm"
    avg_speed.toFixed(2) + " ppm"
  );
  add_data_row(
    doc_obj,
    238 + 45,
    "-T1 Final",
    "Product Cost",
    data.AvT1 + " %",
    "£" + data.Prd_Cost
  );
  add_data_row(
    doc_obj,
    244 + 45,
    "GA Cost",
    "Extras Cost",
    "£" + data.Ga_Cost,
    "£" + data.Extr_Cost
  );
  add_data_row(
    doc_obj,
    250 + 45,
    "Total KPI",
    "Labour Cost",
    data.KPI + " %",
    "£" + data.Lbr_Cost
  );

  doc_obj.addPage();

  doc_obj.setFontSize(14);

  // Header
  doc_obj.setFillColor(greenColor);
  doc_obj.rect(5, 0, 200, 20, "F");
  doc_obj.setFontSize(12);

  add_data_row_notes(
    doc_obj,
    256 - 246,
    "Notes: ",
    "Total Cost",
    data.Notes,
    "£" + data.Total_Cost
  );

  //Sixth Block
  doc_obj.setFillColor(grayColor);
  doc_obj.rect(5, 263 - 246, 200, 30, "F");
  doc_obj.setFontSize(12);
  doc_obj.setFont("times", "bold");
  // row 1
  doc_obj.text("Average (e): ", 10, 269 - 246); // (text, x, y)
  // row 2
  doc_obj.text("Weight Set Points:", 10, 275 - 246); // (text, x, y)
  doc_obj.text("-T2", 60, 275 - 246); // (text, x, y)
  doc_obj.text("-T1", 80, 275 - 246); // (text, x, y)
  doc_obj.text("Average", 100, 275 - 246); // (text, x, y)
  doc_obj.text("+T1", 130, 275 - 246); // (text, x, y)
  doc_obj.text("+T2", 150, 275 - 246); // (text, x, y)
  doc_obj.text("TNE", 170, 275 - 246); // (text, x, y)
  // row 3
  // let TNE = ((st_data.Set_Point-st_data.T1SP)/2).toFixed(2)
  let TNE = Math.abs(st_data.Set_Point - st_data.T1SP).toFixed(2);
  doc_obj.text("Set Points:", 10, 281 - 246); // (text, x, y)
  doc_obj.setFont("times", "normal");
  // doc_obj.text((data.Avg_Wght-(2*TNE)).toFixed(2)+"g", 62, 281); // (text, x, y)
  doc_obj.text((st_data.T1SP - TNE).toFixed(2) + "g", 62, 281 - 246); // (text, x, y) (-T2)
  // doc_obj.text((data.Avg_Wght-(1*TNE)).toFixed(2)+"g", 82, 281); // (text, x, y)
  doc_obj.text(st_data.T1SP.toFixed(2) + "g", 82, 281 - 246); // (text, x, y) (-T1)
  // doc_obj.text(data.Avg_Wght+"g", 102, 281); // (text, x, y)
  doc_obj.text(st_data.Set_Point + "g", 102, 281 - 246); // (text, x, y) (Average)
  doc_obj.text((st_data.Set_Point + 1 * TNE).toFixed(2) + "g", 132, 281 - 246); // (text, x, y)
  doc_obj.text((st_data.Set_Point + 2 * TNE).toFixed(2) + "g", 152, 281 - 246); // (text, x, y)
  doc_obj.text(TNE + "g", 172, 281 - 246); // (text, x, y)
  //row 4
  doc_obj.setFont("times", "bold");

  doc_obj.text("Batch Results", 10, 290 - 246); // (text, x, y)
  doc_obj.text("Average Results:", 60, 290 - 246); // (text, x, y)
  doc_obj.text("-T1 Final:", 150, 290 - 246); // (text, x, y)

  doc_obj.setFont("times", "normal");
  doc_obj.text(data.Avg_Wght + "g", 110, 290 - 246); // (text, x, y)
  doc_obj.text(data.AvT1 + " %", 180, 290 - 246); // (text, x, y)
  // */

  return doc_obj;
};

const write_report_cw_content = (
  doc_obj,
  check_weigher_data,
  st_data,
  batch_end_data
) => {
  //config
  let greenColor = "#bac9a8";
  let grayColor = "#d0cece";
  doc_obj.setFontSize(14);

  // Header
  doc_obj.setLineWidth(0.5);
  doc_obj.rect(0, 0, 210, 25, "S");

  doc_obj.addImage(mwImg, "PNG", 5, 2, 46, 17);
  // doc_obj.setTextColor('#1f4e78');
  doc_obj.setTextColor("#000000");
  doc_obj.setFontSize(34);
  doc_obj.setFont("times", "bold");
  doc_obj.text("Checkweigher Report", 58, 15);
  doc_obj.addImage(paImg, "PNG", 177, 4.5, 26, 16);

  doc_obj.setFontSize(14);
  doc_obj.setTextColor("#000000");
  doc_obj.setFont("times", "normal");

  // First Block
  doc_obj.setFillColor(greenColor);
  doc_obj.rect(5, 28, 200, 25, "F");
  add_data_row(
    doc_obj,
    36,
    "Batch Number",
    "Program",
    st_data.Batch_Number,
    check_weigher_data.Program_Name
  );
  add_data_row(
    doc_obj,
    44,
    "Start Time",
    "Product Weight",
    st_data.Timestamp ? st_data.Timestamp : st_data.TIMESTAMP,
    check_weigher_data.Target_Weight + "g"
  );
  add_data_row(
    doc_obj,
    51,
    "End Time",
    "",
    batch_end_data.Timestamp
      ? batch_end_data.Timestamp
      : batch_end_data.TIMESTAMP
  );

  // Second Block
  doc_obj.setFillColor(grayColor);
  doc_obj.rect(5, 59, 200, 45, "F");

  add_data_row(
    doc_obj,
    67,
    "Average Weight",
    "Line",
    check_weigher_data.Average_Weight + "g",
    st_data.Line
  );
  add_data_row(
    doc_obj,
    75,
    "Total Accepted",
    "",
    check_weigher_data.Accept_Number,
    ""
  );
  add_data_row(doc_obj, 83, "T1 %", "", check_weigher_data.T1T2 + " %", "");
  add_data_row(
    doc_obj,
    91,
    "Standard Deviation",
    "",
    check_weigher_data.Standard_Deviation + "g",
    ""
  );
  add_data_row(
    doc_obj,
    99,
    "Total Weight",
    "",
    check_weigher_data.Total_Weight + "Kg",
    ""
  );

  // Third block
  doc_obj.setFillColor(greenColor);
  doc_obj.rect(5, 110, 200, 80, "F");
  doc_obj.setFontSize(14);
  doc_obj.setFont("times", "bold");
  doc_obj.text("Results:", 10, 114);

  add_data_row(
    doc_obj,
    120,
    "Max Weight",
    "Hi Hi Qt.",
    check_weigher_data.Max_Weight + "g",
    check_weigher_data.HiHi_Number
  );
  add_data_row(
    doc_obj,
    127,
    "Min Weight",
    "Over Qt.",
    check_weigher_data.Min_Weight + "g",
    check_weigher_data.Over_Number
  );
  add_data_row(
    doc_obj,
    133,
    "Range Weight",
    "Under Qt,",
    check_weigher_data.Range + "g",
    check_weigher_data.Under_Number
  );
  add_data_row(
    doc_obj,
    140,
    "Total Weight All",
    "Lo Lo Qt.",
    check_weigher_data.Total_Weight_All + "Kg",
    check_weigher_data.LoLo_Number
  );
  add_data_row(
    doc_obj,
    147,
    "Nominal Weight",
    "Pass Qt.",
    check_weigher_data.Nominal_Weight + "g",
    check_weigher_data.PassNumber
  );
  add_data_row(
    doc_obj,
    153,
    "Shift Weight",
    "Metal Qt.",
    check_weigher_data.Shift_Weight + "g",
    check_weigher_data.Metal_Number
  );
  add_data_row(
    doc_obj,
    160,
    "T1",
    "Abnormal Qt.",
    check_weigher_data.T1 + "g",
    check_weigher_data.Abnormal_Number
  );
  add_data_row(
    doc_obj,
    167,
    "T2",
    "External Qt.",
    check_weigher_data.T2 + "g",
    check_weigher_data.External_Number
  );
  add_data_row(
    doc_obj,
    174,
    "Nominal Speed",
    "Ex. Metal Qt.",
    check_weigher_data.Nominal_Speed,
    check_weigher_data.Ex_Metal_Number
  );
  add_data_row(
    doc_obj,
    180,
    "CP",
    "Total Qt.",
    check_weigher_data.CP,
    check_weigher_data.Total_Number
  );
  add_data_row(
    doc_obj,
    187,
    "CPK",
    "T1-T2 Qt.",
    check_weigher_data.CPK,
    check_weigher_data.T1T2_Number
  );

  // Block 4
  doc_obj.setFillColor(grayColor);
  doc_obj.rect(5, 205, 200, 60, "F");
  doc_obj.setFontSize(14);
  doc_obj.setFont("times", "bold");
  doc_obj.text("Settings:", 10, 212);

  add_data_row(
    doc_obj,
    218,
    "Target Weight",
    "Hi Hi Limit",
    check_weigher_data.Target_Weight + "g",
    check_weigher_data.HiHi_Limit + "g"
  );
  add_data_row(
    doc_obj,
    225,
    "Tare Weight",
    "Hi Limit",
    check_weigher_data.Tare_Weight + "g",
    check_weigher_data.Hi_Limit + "g"
  );
  add_data_row(
    doc_obj,
    232,
    "Dynamic Comp",
    "Low Limit",
    check_weigher_data.Dynamic_Comp + "g",
    check_weigher_data.Low_Limit + "g"
  );
  add_data_row(
    doc_obj,
    239,
    "Limit 2",
    "Low Low Limit",
    check_weigher_data.Limit2 + "Kg",
    check_weigher_data.LoLo_Limit + "g"
  );
  add_data_row(
    doc_obj,
    246,
    "Limit 3",
    "Node",
    check_weigher_data.Limit3 + "g",
    check_weigher_data.Mode
  );
  add_data_row(
    doc_obj,
    253,
    "Program Number",
    "",
    check_weigher_data.Program_Number,
    ""
  );

  return doc_obj;
};

export const generateReport = (jsoned_batch_data, check_weigher_data) => {
  console.log("JSON Batched DATA => ", jsoned_batch_data);
  console.log("Check WEIGHER Data => ", check_weigher_data);

  let batch_end_data = [];
  let batch_start_data = [];
  let target_speed = 0;
  let batch_runtime = 0;
  let scales_ppm = {}; // this dict will be having Line leaders as keys
  let scales_t1 = {};
  // let scales_kpi = {};
  let scales_sp = {};
  let scales_weight = {};
  let batch_scale_data = [];

  for (let i = 0; i < jsoned_batch_data.length; i++) {
    let scale_record = jsoned_batch_data[i]._source;
    if (scale_record.FLAG === "END") {
      batch_end_data = jsoned_batch_data[i]._source;
      batch_runtime = batch_end_data.Run_Time;
      continue;
    } else if (scale_record.FLAG === "START") {
      batch_start_data = jsoned_batch_data[i]._source;
      target_speed = batch_start_data.Speed;
      continue;
    }
    if (scale_record.FLAG === "SCALE") {
      batch_scale_data.push(scale_record);
    }
    let operator = scale_record.Op_Name;
    let ppm = scale_record.PPM;
    let t1ppm = scale_record.T1PPM;
    let weight = scale_record.Avg_Wght;
    let sp = scale_record.Set_Point;
    if (operator in scales_ppm) {
      scales_ppm[operator] += ppm;
      scales_t1[operator] += t1ppm;
      scales_sp[operator] += sp;
      scales_weight[operator] += weight;
      //>> to do below check averges
      scales_weight[operator] /= 2;
      //scales_kpi[operator] = (ppm / batch_runtime) * target_speed;
    } else if (operator != undefined) {
      scales_ppm[operator] = ppm;
      scales_t1[operator] = t1ppm;
      scales_sp[operator] = sp;
      scales_weight[operator] = weight;
    }
  }

  const sum = batch_scale_data.reduce((acc, obj) => acc + obj.PPM, 0);
  const avg_speed = sum / batch_scale_data.length;

  let doc = new jsPDF();
  doc.setFont("times");
  // doc.rect() x,y,w,h
  // doc.text(text,x,y, options)
  // doc.setFont('Helvetica');
  // console.log("Scales PPM:");
  // console.log(scales_ppm);
  write_report_content(
    doc,
    batch_end_data,
    batch_start_data,
    scales_ppm,
    scales_t1,
    scales_sp,
    scales_weight,
    batch_runtime,
    target_speed,
    avg_speed
  );

  if (Object.keys(check_weigher_data).length > 0) {
    doc.addPage();
    write_report_cw_content(
      doc,
      check_weigher_data,
      batch_start_data,
      batch_end_data
    );
  }

  // doc.save("a4.pdf");
  return doc;
};
