import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Spinner } from "reactstrap";
import InProgressLines from "./InProgressLines";
import axios from "axios";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";
import InProgressPpmGraph from "./InProgressPpmGraph";
import OverallLinesCardData from "./OverallLinesCardData";

function getCurrentDateTime(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

function InProgressData({ clientId }) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [thresholdValues, setThresholdValues] = useState({
    // kpiThreshold: 100,
    offlineCheckThreshold: 30, //min
  });

  const timerRef = useRef(null);

  const today = new Date();
  const yesterday = new Date(today);
  // yesterday.setDate(yesterday.getDate() - 1);
  yesterday.setHours(yesterday.getHours() - 8);

  const getData = (key) => {
    const todayGetData = new Date();
    const yesterdayGetData = new Date(today);
    yesterdayGetData.setHours(yesterdayGetData.getHours() - 8);

    axios
      .get(`${baseUrl}/api/dashboard/inProgressData`, {
        params: {
          start_date: yesterdayGetData.toISOString(),
          // start_date: "2016-01-01T00:00:00Z",
          end_date: todayGetData.toISOString(),
          // end_date: "2022-08-04T23:59:59Z",
          clientId,
          initialReq: key,
          offlineCheckThreshold: thresholdValues.offlineCheckThreshold,
        },
        headers: authHeader(),
      })
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
        timerRef.current = setTimeout(() => {
          getData("no");
        }, 30000);
      })
      .catch((err) => {
        console.log(err);
        setData([]);
        setIsLoading(false);
        timerRef.current = setTimeout(() => {
          getData("no");
        }, 30000);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    getData("yes");
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [clientId]);

  return (
    <div className="header bg-transparent py-0 mt-4">
      <Container fluid>
        <div className="header-body">
          <Row className="mb-2">
            <Col xs="12" md="6" className="text-left mb-2">
              <span className="h1" style={{ color: "#a7ce39" }}>
                Current Status
              </span>
              <span className="h2 text-muted ml-5">
                {/* {yesterday.toDateString()} */}
                {/* {yesterday.toISOString().replace('T', ' ---- ')} */}
                {/* {getCurrentDateTime(yesterday)} */}
                Last Updated Time
                {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                {/* {today.toDateString()} */}
                {/* {today.toISOString().replace('T', ' ---- ')} */}
                {' '}
              </span>
              <span className="h2 text-muted ml-5">
                {/* {yesterday.toDateString()} */}
                {/* {yesterday.toISOString().replace('T', ' ---- ')} */}
                {/* {getCurrentDateTime(yesterday)} */}
                {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                {/* {today.toDateString()} */}
                {/* {today.toISOString().replace('T', ' ---- ')} */}
                {' '}
                {getCurrentDateTime(today)}
              </span>
            </Col>
          </Row>
          <Row>
            <>
              <Col xs="12" className="mt-4">
                <Row className="">
                  <Col
                    style={{ maxWidth: "unset", flexGrow: 1 }}
                    xs="12"
                    md="2"
                  >
                    <OverallLinesCardData
                      isLoading={isLoading}
                      title="PPM per Operator"
                      value={
                        data.cardDataValues
                          ? Math.round(data.cardDataValues.ppmPerOperator) || 0
                          : 0
                      }
                      toolTipData={{
                        id: "ppm-per-operator",
                        message:
                          "Packs per minute per Operator of all lines (approx)",
                      }}
                    />
                  </Col>
                  <Col
                    style={{ maxWidth: "unset", flexGrow: 1 }}
                    xs="12"
                    md="2"
                  >
                    <OverallLinesCardData
                      isLoading={isLoading}
                      title="PPM overall"
                      value={
                        data.cardDataValues
                          ? Math.round(data.cardDataValues.ppmOverall) || 0
                          : 0
                      }
                      toolTipData={{
                        id: "ppm-overall",
                        message: "Overall packs per minute (approx)",
                      }}
                    />
                  </Col>
                  <Col
                    style={{ maxWidth: "unset", flexGrow: 1 }}
                    xs="12"
                    md="2"
                  >
                    <OverallLinesCardData
                      isLoading={isLoading}
                      title="GA percentage"
                      value={
                        `${data.cardDataValues
                          ? data.cardDataValues.giveawayPercentage || 0
                          : 0} %`
                      }
                      toolTipData={{
                        id: "ga-percentage",
                        message: "Give away percentage",
                      }}
                    />
                  </Col>
                  <Col
                    style={{ maxWidth: "unset", flexGrow: 1 }}
                    xs="12"
                    md="2"
                  >
                    <OverallLinesCardData
                      isLoading={isLoading}
                      title="Tonnage"
                      value={
                        data.cardDataValues
                          ? (data.cardDataValues.tonnage / 1000).toFixed(2) || 0
                          : 0
                      }
                      toolTipData={{
                        id: "tonnage-tooltip",
                        message: "Overall Tonnage (Kg) per minute (approx)",
                      }}
                    />
                  </Col>
                  <Col
                    style={{ maxWidth: "unset", flexGrow: 1 }}
                    xs="12"
                    md="2"
                  >
                    <OverallLinesCardData
                      isLoading={isLoading}
                      title="T1PPM"
                      value={
                        data.cardDataValues
                          ? data.cardDataValues.t1ppm.toFixed(2) || 0
                          : 0
                      }
                      toolTipData={{
                        id: "T1PPM-tooltip",
                        message: "Overall T1PPM (approx)",
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs="12" className="mt-4">
                <InProgressPpmGraph
                  isLoading={isLoading}
                  data={data.dataArray}
                />
              </Col>
            </>
            {/* {isLoading ? (
              <Col xs="12" className="text-center mt-6">
                <Spinner />
              </Col>
            ) : data.dataArray && data.dataArray.length > 0 ? (
              <>
                <Col xs="12" className="mt-4">
                  <Row className="">
                    <Col
                      style={{ maxWidth: "unset", flexGrow: 1 }}
                      xs="12"
                      md="2"
                    >
                      <OverallLinesCardData
                        title="PPM per Operator"
                        // value={
                        //   data.cardDataValues.ppmPerOperator.toFixed(2) || 0
                        // }
                        value={
                          Math.round(data.cardDataValues.ppmPerOperator) || 0
                        }
                        toolTipData={{
                          id: "ppm-per-operator",
                          message:
                            "Packs per minute per Operator (approximately)",
                        }}
                      />
                    </Col>
                    <Col
                      style={{ maxWidth: "unset", flexGrow: 1 }}
                      xs="12"
                      md="2"
                    >
                      <OverallLinesCardData
                        title="PPM overall"
                        value={Math.round(data.cardDataValues.ppmOverall) || 0}
                        toolTipData={{
                          id: "ppm-overall",
                          message:
                            "Sum of Packs per minute of all lines (approximately)",
                        }}
                      />
                    </Col>
                    <Col
                      style={{ maxWidth: "unset", flexGrow: 1 }}
                      xs="12"
                      md="2"
                    >
                      <OverallLinesCardData
                        title="GA percentage"
                        value={data.cardDataValues.giveawayPercentage || 0}
                        toolTipData={{
                          id: "ga-percentage",
                          message: "Give away percentage",
                        }}
                      />
                    </Col>
                    <Col
                      style={{ maxWidth: "unset", flexGrow: 1 }}
                      xs="12"
                      md="2"
                    >
                      <OverallLinesCardData
                        title="Tonnage"
                        value={
                          (data.cardDataValues.tonnage / 1000).toFixed(2) || 0
                        }
                        toolTipData={{
                          id: "tonnage-tooltip",
                          message: "Overall Tonnage (Kg) per minute",
                        }}
                      />
                    </Col>
                    <Col
                      style={{ maxWidth: "unset", flexGrow: 1 }}
                      xs="12"
                      md="2"
                    >
                      <OverallLinesCardData
                        title="T1PPM"
                        value={data.cardDataValues.t1ppm || 0}
                        toolTipData={{
                          id: "T1PPM-tooltip",
                          message: "Overall T1PPM (approximately)",
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" className="mt-4">
                  <InProgressPpmGraph data={data.dataArray} />
                </Col>
              </>
            ) : (
              <Col xs="12" className="text-center mt-6"></Col>
            )} */}
          </Row>
          <Row>
            {isLoading ? (
              Array(3)
                .fill()
                .map((item) => (
                  <Col xs="12" className="mt-4">
                    <InProgressLines
                      data={item}
                      clientId={clientId}
                      thresholdValues={thresholdValues}
                      isLoading={isLoading}
                    />
                  </Col>
                ))
            ) : data.dataArray && data.dataArray.length > 0 ? (
              data.dataArray
                .sort((a, b) => a.line - b.line)
                .map((item) => {
                  return (
                    <Col xs="12" className="mt-4">
                      <InProgressLines
                        data={item}
                        scaleData={data.scaleData}
                        clientId={clientId}
                        thresholdValues={thresholdValues}
                        isLoading={isLoading}
                      />
                    </Col>
                  );
                })
            ) : (
              <Col xs="12" className="text-center mt-6">
                No Data Found
              </Col>
            )}
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default InProgressData;
